/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { NewJobPayload } from '../../models/new-job.model';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { environment } from 'apps/studio/src/environments/environment';

@Injectable({ providedIn: 'root' })
export class JobService {
	constructor(private http: HttpClient) {}

	newJob(data: NewJobPayload) {
		return this.http.post(`${environment.api_url}v1/job_action/new_job/`, data);
	}

	updateJob(data: any) {
		return this.http.post(`${environment.api_url}v1/job_action/update_job/`, data);
	}

	assignTeam(data: any) {
		return this.http.post(`${environment.api_url}v1/job_action/assign_team/`, data);
	}

	newAppointment(appointment_request: any) {
		return this.http.post(`${environment.api_url}v1/job_action/new_appointment/`, appointment_request);
	}

	generateBOQReport(job_id: number) {
		return this.http.post<any>(`${environment.api_url}v1/boq_action/generate_pdf/`, { job_id });
	}

	cancelJob(data: any) {
		return this.http.post(`${environment.api_url}v1/job_action/cancel_job/`, data);
	}

	// need to manually check the success or fail as well as the reason
	retryInvoiceOnMaven(params: { job_id: number }) {
		return this.http.post(`${environment.api_url}v1/job_action/retry_upload_invoice_to_maven/`, {
			job_id: params.job_id
		});
	}
	/**
	 * currently new_state and force note are unused, though it is possible to include them
	 */
	forceSpAppointment(params: { job_id: number; force_payment?: 'Y' | 'N'; new_state?: number; force_note?: string }) {
		const { job_id, force_payment } = params;

		return this.http.post(`${environment.api_url}v1/job_action/retry_appoint_sp_on_maven/`, {
			job_id,
			force_payment
		});
	}

	getClaimAppointments(params: { job_id: number }): Observable<any> {
		return this.http.post(`${environment.api_url}v1/job_action/get_claim_appointments`, { job_id: params.job_id });
	}

	changeAppointment(params: {
		appointment: { appointment_type: number; range_start: string; reason: string };
		job_id: number;
		job_information: { return_to_state: number };
		office_use?: {
			appointment_type: string;
			appointment_type_id: string;
			appointmentdatetype: string;
			requestedappointmentdate: string;
			requestedappointmenttime: string;
		};
	}): Observable<any> {
		return this.updateJob(params);
	}

	getAllJobs(): Observable<any> {
		return this.http.get(environment.api_url + 'v1/job/');
	}

	getFullJob(job_id: number): Observable<any> {
		// return this.http.get<any[]>(environment.api_url + `v1/job/${jobId}/`);
		return this.http.post(`${environment.api_url}v1/job_action/get_job`, { job_id });
	}

	getSPReplyList(job_id: number): Observable<any> {
		return this.http.get<any>(`${environment.api_url}v1/job_action/get_sps?job_id=${job_id}`);
	}

	manuallyAllocateJob(data: { jobId: number; spId: number }) {
		return this.http.post<any>(`${environment.api_url}v1/job_action/appoint_sp`, {
			job_id: data?.jobId,
			sp_id: data?.spId
		});
	}

	repingJob(data: { job_id: number; appointments: any[] }) {
		return this.http.post<any>(`${environment.api_url}v1/job_action/reping_job`, data);
	}

	assignAssessor(data: { job_id: number; assessor_id: number }) {
		return this.http.post<any>(`${environment.api_url}v1/job_action/assign_assessor`, {
			job_id: data?.job_id,
			assessor_id: data?.assessor_id
		});
	}

	transform(names: any[], args: any[]): any {
		return names.filter(name => name?.toLowerCase()?.indexOf(args[0]?.toLowerCase()) !== -1);
	}

	getJobInvoice(job_id: number) {
		return this.http.post<any>(environment.api_url + `v1/file_action/get_invoice/`, {
			job_id,
			return_type: 1
		});
	}

	getInterestedParties(data: { job_id: number }) {
		return this.http.post(`${environment.api_url}v1/job_action/get_interested_parties/`, {
			job_id: data?.job_id
		});
	}

	getVoucherData(job_id: number) {
		return this.http.post(`${environment.api_url}v1/job_action/get_voucher_details/`, { job_id: job_id });
	}
}
