// import { Injectable } from '@angular/core';
// import { BehaviorSubject } from 'rxjs';
// import { Store } from '@ngrx/store';
// import { BigFormService } from './big-form.service';

// @Injectable({ providedIn: 'root' })
// export class ModalService {
// 	modalActions = new BehaviorSubject<Function>(null);

// 	openModalDirectly(func: (instance, store?: Store<any>, bf?: BigFormService) => void) {
// 		this.modalActions.next(func);
// 	}
// }

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { BigFormService } from './big-form.service';
import { FLXModalComponent } from '@flexus/ui-elements';



@Injectable({ providedIn: 'root' })
export class ModalService {
	modalActions = new BehaviorSubject<Function>(null);
	private currentModalInstance: FLXModalComponent | null = null;

	openModalDirectly(func: (instance, store?: Store<any>, bf?: BigFormService) => void) {
		this.modalActions.next((instance: FLXModalComponent) => {
			this.currentModalInstance = instance;
			func(instance);
		});
		
	}

	close() {
		if(this.currentModalInstance){
			this.currentModalInstance.close();
			this.currentModalInstance = null;
		}
	}
}
