import { getFullItemTwo, NodePoint_0_0_2 } from '@flexus/core';
import { filter, map, take } from 'rxjs';

export const PHOTO_NODE = (state: number,new_state: number, isBuildersJob?: boolean): NodePoint_0_0_2=> {
	return {
	name: 'Photos',
	initFormFields: (bf, item, instance, storeQuery, store) => {
		isBuildersJob = true
		store.select(getFullItemTwo).pipe(
			filter(Boolean),
			take(1),
			map(item => {
				if(item.claim.source_id === 4) {
					isBuildersJob = true
					console.log('BUILDERS CHECK PHOTO 26item', isBuildersJob)
				} else {
					isBuildersJob = false
					console.log('BUILDERS CHECK PHOTO 26item', isBuildersJob)
				}
			})
		).subscribe()
	},
	
	component: 'FLXViewPhotosComponent',
	inputs: {
		heading: 'Job Photos',
		location: 'context-menu'
	},
	showTabs: true,
	serverCalls: {
		photos: {
			serviceVariable: 'spService',
			functionName: 'getAllJobFiles',
			errorMessage: 'Could not get files from server!',
			responseSlice: 'payload'
		}
	},
	navs: [
		{
			text: 'manually upload photos',
			color: 'secondary',
			location: 'center',
			visible: (bf, store) => {
				return store.select(getFullItemTwo).pipe(
					filter(Boolean),
					map(item => state === 26 && item.claim.source_id === 4)
				);
			},
			// serverCalls: {
			// 	submitForPayment: {
			// 		serviceVariable: 'spService',
			// 		functionName: 'buildersSubmitForPayment',
			// 		errorMessage: 'Could not submit for payment!'
			// 	}
			// }
			nextNode: 'UploadPhotos'
		},
		{
			text: 'photos are correct',
			color: 'primary',
			visible: (bf, store) => {
				return store.select(getFullItemTwo).pipe(
					filter(Boolean),
					map(item => state === 26 && item.claim.source_id === 4)
				);
			},
			serverCalls: {
				submitForPayment: {
					serviceVariable: 'spService',
					functionName: 'buildersSubmitForPayment',
					errorMessage: 'Could not submit for payment!'
				}
			}
		},
		// {
		// 	text: 'do submit for payment',
		// 	color: 'primary',
		// 	nextNode: 'RequestAdditionalFeesReason',
		// 	visible: (bf, store, networkService) => {
		// 		return store.select(getFullItemTwo).pipe(
		// 			filter(Boolean),
		// 			map(item => state === 26 && item.claim.source_id !== 4)
		// 		);
		// 	}
		// },
	]	
}
};
