import { Flow_0_0_2, getFullItemTwo, getAllInfo, getSelectedItemTwo, getSelectedItem } from '@flexus/core';
import { map, skipWhile, take } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { environment } from 'apps/studio/src/environments/environment';
import { findName, isNumber } from '@flexus/utilities';
import { KVLHeading } from '@flexus/ui-elements';
import moment from 'moment';

export const PGG_SP_CONTEXT_MENU_VIEW_JOB_SUMMARY: Flow_0_0_2 = {
	id: 'JobSummary',
	itemType: 'flow',
	includeForOnlyStates: ['all'],
	name: 'Job Summary',
	header: {
		title: (store: any) => {
			return store.select(getFullItemTwo).pipe(
				map((itemTwo: any) => {
					if (itemTwo) {
						if (itemTwo?.claim?.applicant) {
							return `Summary for this job: ${itemTwo?.claim?.loan_information?.voucher_key} - ${itemTwo?.claim?.applicant?.first_name}`;
						} else {
							return 'Job Summary';
						}
					}
				})
			);
		},
		controls: () => () => []
	},

	footer: {
		type: 'node_nav'
	},
	actionPanel: instance => {
		return () => {
			return [{}];
		};
	},
	startNode: 'JobSummary',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	serverCalls: {},
	nodes: {
		JobSummary: {
			component: {
				children: [
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Voucher Summary', 'secondary'),
							data$: 'vouchersummaryKeyValues',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Job Information', 'secondary'),
							data$: 'installerKeyValues'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Customer Info', 'secondary'),
							data$: 'customerContactKeyValues'
						}
					}
				]
			},
			serverCalls: {
				vouchersummaryKeyValues: {
					errorMessage: 'Something went wrong with installation info',
					directCall: (http: any, store: any) => {
						return forkJoin([
							store.select(getFullItemTwo).pipe(
								skipWhile((itt: any) => !itt),
								take(1),
								map(res => res as any)
							),
							store.select(getAllInfo).pipe(
								skipWhile((ai: any) => !ai),
								take(1),
								map(res => res as any)
							)
							// ,
							// store.select(getSelectedItem).pipe(
							// 	skipWhile((x: any) => !x),
							// 	take(1)
							// )
						]).pipe(
							map(([itemTwo, allInfo]: any) => {
								const {claim} = itemTwo
								const {loan_information} = claim
								const {vouchers} = loan_information
								const {quantity} = vouchers
								let appointmentname;
								const { appointment_types } = allInfo;
								const voucher_name: string = itemTwo?.claim?.loan_information?.voucher_type ?? 'No voucher type';
								const street_adress: string = itemTwo?.claim?.address ?? 'No address';
								const customer_phone: string = itemTwo?.claim?.applicant?.contact_number ?? 'No contact number';
								const instructions: string = itemTwo?.claim?.loan_information?.whatmatters ?? 'No special instructions';
								const onsiteperson: string = itemTwo?.claim?.loan_information?.onsiteperson ?? 'No onsite person';
								const onsitecontact: string = itemTwo?.claim?.loan_information?.onsitecontact ?? 'No onsite contact';
								const appointmentdate: string = itemTwo?.appointment[0]?.range_start ?? 'No appointment date';
								const appointmenttype = itemTwo?.appointment[0]?.appointment_type;
								for (let i = 0; i < appointment_types?.length; i++) {
									if (appointmenttype === appointment_types[i]?.id) {
										appointmentname = appointment_types[i]?.name;
									}
								}
								const vouchersummary = {
									Product: `${voucher_name}`,
									Address: `${street_adress}`,
									Phone: `${customer_phone}`,
									Quantity: quantity ? `${quantity}` : 'No quantity data available',
									'Special Instructions': `${instructions}`,
									'On-site Person': `${onsiteperson}`,
									'On-site Concact no.': `${onsitecontact}`,
									'Appointment Date': `${moment(appointmentdate).format(moment.HTML5_FMT.DATE)} ${appointmentname} ${moment(appointmentdate).format(moment.HTML5_FMT.TIME)}`
								};

								return [vouchersummary];
							})
						);
					}
				},
				installerKeyValues: {
					errorMessage: 'Something went wrong with installation info',
					directCall: (http: any, store: any) => {
						return forkJoin([
							store.select(getFullItemTwo).pipe(
								skipWhile((itt: any) => !itt),
								take(1),
								map(res => res as any)
							),
							store.select(getAllInfo).pipe(
								skipWhile((ai: any) => !ai),
								take(1),
								map(res => res as any)
							),
							store.select(getSelectedItem).pipe(
								skipWhile((x: any) => !x),
								take(1)
							),
							http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
								skipWhile((x: any) => !x),
								take(1),
								map((res: any) => res['payload'])
							)
						]).pipe(
							map(([itemTwo, allInfo, selectedItem, team]: any) => {
								console.log('selected item', selectedItem);
								const sp = itemTwo?.sp;
								const sps = allInfo?.sps;
								const supplier_type = allInfo?.supplier_type;
								let companyname;
								// let accreditation_number;
								let qualification_number;
								for (let i = 0; i < sps.length; i++) {
									if (sp === sps[i]?.id) {
										companyname = sps[i]?.name;
									}
								}
								const tleads = team as any;
								let teamleadname;
								for (let i = 0; i < tleads.length; i++) {
									if (!itemTwo.team_leader) {
										teamleadname = 'Team leader not assigned yet';
									} else if (tleads[i]?.id === itemTwo?.team_leader.id) {
										teamleadname = tleads[i]?.full_name;
									}
								}
								// const accreditation_number =
								if (!itemTwo.team_leader || !itemTwo.team_leader.sp) {
									qualification_number = 'No qualification number for the technician';
								} else {
									qualification_number = itemTwo?.team_leader.qualification_number;
								}
								const installerinfo = {
									Id: selectedItem.id,
									'Claim Type Id': selectedItem.claim_type_id,
									'Assessor Name': selectedItem.assessor_name ? selectedItem.assessor_name : 'No assessor appointed',
									Lat: selectedItem.lat,
									Long: selectedItem.long,
									Source: selectedItem.source,
									Suburb: selectedItem.suburb,
									Address: itemTwo.address,
									'Postal Code': selectedItem.postal_code,
									Updated: selectedItem.updated,
									Skill: selectedItem.skillName,
									Sp: isNumber(selectedItem.sp) ? (findName(selectedItem.sp, sps)['name'] ? findName(selectedItem.sp, sps)['name'] : 'sp not found') : selectedItem.sp,
									State: selectedItem.stateName,
									'Supplier Type': isNumber(selectedItem.supplier_type) ? findName(selectedItem.supplier_type, supplier_type)['name'] : selectedItem.supplier_type
								};

								return [installerinfo];
							})
						);
					}
				},
				customerContactKeyValues: {
					errorMessage: 'Something went wrong customer contact info',
					directCall: (http: any, store: any, sq) => {
						return forkJoin([
							store.select(getFullItemTwo).pipe(
								skipWhile((itt: any) => !itt),
								take(1),
								map(res => res as any)
							),
							http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
								skipWhile((x: any) => !x),
								take(1),
								map((res: any) => res['payload'])
							)
						]).pipe(
							map(([itemTwo, teamleaders]: any) => {
								const tleads = teamleaders as any;
								let teamleadname;
								let joblocation;
								for (let i = 0; i < tleads.length; i++) {
									if (tleads[i]?.id === itemTwo?.team_leader) {
										teamleadname = tleads[i]?.full_name;
									}
								}
								// if (!itemTwo.location) {
								//   joblocation = 'No LatLng data provived';
								// } else if (itemTwo.location === '') {
								//   joblocation = 'No LatLng data provived';
								// } else {
								//   joblocation = 'itemTwo.location';
								// }
								if (!itemTwo?.claim?.loan_information) {
									joblocation = 'The customer misses data';
								}
								if (!itemTwo?.claim?.loan_information?.claimlatitude || !itemTwo?.claim?.loan_information?.claimlongitude) {
									joblocation = 'At lease one coordinate not supplied!';
								}
								if (itemTwo?.claim?.loan_information?.claimlatitude && itemTwo?.claim?.loan_information?.claimlongitude) {
									const latitude = itemTwo?.claim?.loan_information?.claimlatitude;
									const longitude = itemTwo?.claim?.loan_information?.claimlongitude;
									joblocation = `${latitude}, ${longitude}`;
								}

								const customerContactData = {
									'Onsite Person': `${itemTwo.claim?.loan_information.onsiteperson}`,
									'Contact number': `${itemTwo.claim?.applicant?.contact_number}`,
									'Cell Number': itemTwo?.claim?.loan_information.onsitecontact,
									'Alternative Contact numbers': itemTwo?.claim?.loan_information.onsitecontact,
									/*
// Removes duplicate contact numbers for onsite person comparing cell phone number and contact number
										[itemTwo.claim.loan_information.policydetails.CellPhoneNumber, itemTwo?.claim.loan_information.onsitecontact]
											.filter(phoneNumber => !!phoneNumber)
											.filter(
												phoneNumber => itemTwo?.claim.loan_information.contactnumber !== phoneNumber && itemTwo?.claim.loan_information.policydetails?.CellPhoneNumber !== phoneNumber
											)
											.join(', ') || '-', */

									// 'Full name ': `${itemTwo?.claim?.applicant?.first_name} ` + `${itemTwo?.claim?.applicant?.surname}`,
									// 'Installation Address': `${itemTwo?.claim?.loan_information?.propertystreetaddress},` + ' ' + `${itemTwo?.claim?.loan_information?.propertysuburb}`,
									'Email address ': itemTwo?.claim?.applicant?.information?.email_address ?? 'No email address returned'

									// // 'GPS Coordinates ': itemTwo ? itemTwo?.location : '--'
									// 'GPS Coordinates ': joblocation
								};
								return [customerContactData];
							})
						);
					}
				}
			}
		}
	}
};
