<ng-container *ngIf="laidOutButtons$ | async as buttons">
	<div [ngClass]="containerClass">
		<div *ngIf="buttons.left.length > 0" class="button-set-column left-column">
			<flx-button
				*ngFor="let button of buttons.left; index as idx"
				[ngClass]="getClass(button)"
				(click)="handleClick(button, idx)"
				[disabled]="button.dynamic_btn_disable"
				[color]="button.color"
				[size]="button.size"
				>{{ button.dynamic_btn_name }}</flx-button
			>
		</div>
		<div *ngIf="buttons.middle.length > 0" class="button-set-column middle-column">
			<flx-button
				*ngFor="let button of buttons.middle; index as idx"
				[ngClass]="getClass(button)"
				(click)="handleClick(button, idx)"
				[disabled]="button.dynamic_btn_disable"
				[color]="button.color"
				[size]="button.size"
				>{{ button.dynamic_btn_name }}</flx-button
			>
		</div>
		<div *ngIf="buttons.right.length > 0" class="button-set-column right-column">
			<flx-button
				*ngFor="let button of buttons.right; index as idx"
				[ngClass]="getClass(button)"
				(click)="handleClick(button, idx)"
				[disabled]="button.dynamic_btn_disable"
				[color]="button.color"
				[size]="button.size"
				>{{ button.dynamic_btn_name }}</flx-button
			>
		</div>
	</div>
</ng-container>
