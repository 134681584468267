/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { BigFormService, Flow_0_0_2, getAllInfo, getFullItemTwo, ManifestController, ModalService } from '@flexus/core';
import { KVLHeading } from '@flexus/ui-elements';
import { Store } from '@ngrx/store';
import { setActionPanelItems, CollapseActionPanel } from '../../../../app-shell-features';
import gql from 'graphql-tag';
import { EMPTY, forkJoin, of } from 'rxjs';
import { map, skipWhile, take } from 'rxjs/operators';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { environment } from 'apps/studio/src/environments/environment';
import moment from 'moment';
import { UntypedFormControl,  Validators } from '@angular/forms';

export const PGG_298: Flow_0_0_2 = {
	id: '298',
	name: 'upload-missing-photos',
	itemType: 'flow',

	actionPanel: instance => setActionPanelItems(instance, ['notes', 'documents', 'installation-summary']),

	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store: any) => {
			return store.select(getFullItemTwo).pipe(
				map((itemTwo: any) => {
					if (itemTwo?.claim) {
						if (itemTwo?.claim?.applicant && itemTwo?.claim?.loan_information) {
							return `Upload Missing Photos : ${itemTwo?.claim?.mid} - ${itemTwo?.claim?.applicant?.first_name} ${itemTwo?.claim?.applicant?.surname}`;
						} else {
							return 'Upload Missing Photos';
						}
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	instructions: {
		editRoles: {
			0: 'Upload Missing Photos'
		},
		viewRoles: {
			0: 'SP Uploading Missing Photos'
		}
	},
	serverCalls: {
		skills: {
			serviceVariable: 'mulSpService',
			functionName: 'getSkills',
			responseSlice: 'skills',
			errorMessage: 'No skills were found!'
		},
		files: {
			serviceVariable: 'mulSpService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		}
	},
	startNode: 'UploadMissingPhotosNotice',
	nodes: {
		UploadMissingPhotosNotice: {
			//nodeType: 'singleView',
			initFormFields: (_bf: BigFormService) => {
				_bf.patchValues({ new_state: 299 });
			},
			errorHandler: {
				displayFormat: 'inline',
				retryPolicy: 'auto',
				onRetryComplete: () => EMPTY
			},
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Job Information',
							itemMargin: '0 0 35px 0',
							headingConfig: {
								size: 'medium',
								type: 'page',
								color: 'default'
							}
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							colouredHeading: new KVLHeading('Important Information', 'secondary'),
							data$: 'importantKeyValues',
							color: 'secondary',
							itemMargin: '0 0 15px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: `Installation Details`,
							data$: 'installationKeyValues',
							itemMargin: '0 0 35px 0'
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							heading: `Installer Detail's`,
							data$: 'installerDetails',
							itemMargin: '0 0 35px 0'
						}
					}
				]
			},
			serverCalls: {
				importantKeyValues: {
					errorMessage: 'Could not get an important message',
					directCall: (http: HttpClient, _store: Store, _sq: any) => {
						return _sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											job_information {
												return_tl_reasons
											}
										}
									}
								`, //must be the planned locationv // TODO: bar
								_store.select(getFullItemTwo).pipe(
									skipWhile((fit: any) => !fit),
									take(1),
									map((res: any) => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									const no_photos_instruction = queryData?.return_tl_reasons;
                                    console.log('no_photos_instruction', no_photos_instruction);
                                    if(!Array.isArray(no_photos_instruction) && typeof no_photos_instruction === 'object')  {
									const important_info = {
										// 'Message from previous state': 'No instruction n'
										'Comment': `${no_photos_instruction.reason}` ?? 'No instruction'
									};
									return [important_info];
                                }
                                else{
                                    const important_info = {
                                        // 'Message from previous state': 'No instruction n'
                                        'Comment': `${no_photos_instruction[0]?.reason}` ?? 'No instruction'
                                    };
                                    return [important_info];
                                }
								})
							);
					}
				},
				installationKeyValues: {
					errorMessage: 'Something went wrong with installation info',
					directCall: (http, store) => {
						return forkJoin([
							store.select(getFullItemTwo).pipe(
								skipWhile(itt => !itt),
								take(1),
								map(res => res as any)
							),
							store.select(getAllInfo).pipe(
								skipWhile((ai: any) => !ai),
								take(1),
								map(res => res as any)
							)
						]).pipe(
							map(([itemTwo, info]) => {
								const { appointment } = itemTwo;
								const { claim } = itemTwo;
								const { loan_information } = claim;
								const claimtype = itemTwo?.claim?.type;
								let address;
								if (claimtype !== 'Pricelock Installation') {
									//how to test?
									address = `${claim?.address}, ` + `${claim?.suburb}`;
								} else {
									address = `${loan_information?.propertystreetaddress_2}, ` + `${loan_information?.propertysuburb}`;
								}
								// let appointmentdate;
								const appointmentdate = appointment[0]?.range_start;
								const skill = itemTwo?.skill;
								const skill_description = info?.skills.find(el => el.id === skill).name;
								const appointment_type = itemTwo?.claim?.loan_information?.appointment_type;

								const installationdetails = {
									'Installation Type': `${claimtype}`,
									Skill: `${skill_description}`,
									// Address:
									// 	`${itemTwo?.claim?.loan_information?.propertystreetaddress}` +
									// 	' ' +
									// 	`${itemTwo?.claim?.loan_information?.propertystreetaddress_2},` +
									// 	' ' +
									// 	`${itemTwo?.claim?.loan_information?.propertysuburb}`,
									Address: `${address}`,
									'Appointment Date': `${moment(appointmentdate).format(moment.HTML5_FMT.DATE)}`,
									'Appointment Time': `${appointment_type} ${moment(appointmentdate).format(moment.HTML5_FMT.TIME)}`
								};
								return [installationdetails];
							})
						);
					}
				},
				installerDetails: {
					errorMessage: `Couldn't get isntaller's details`,
					directCall: (_http: HttpClient, _store: Store, _sq: any) => {
						return _sq
							.queryObject(
								//no fullItemTwo data on current job
								gql`
									{
										fullItemTwo {
											team_leader {
												full_name
												contact_number
												sp {
													name
												}
											}
										}
									}
								`,
								_store.select(getFullItemTwo).pipe(
									skipWhile((fit: any) => !fit),
									take(1),
									map((res: any) => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((query_data: any) => {
									const full_name = query_data?.full_name ?? 'No name';
									const companyname = query_data?.name ?? 'No company name';
									const contactnumber = query_data?.contact_number ?? 'No contact number';
									const emailaddress = query_data?.email_address ?? 'No email address';
									const installerobj = {
										'Full Name': `${full_name}`,
										'Company Name': `${companyname}`,
										'Contact Number': `${contactnumber}`,
										'Email Address': `${emailaddress}`
									};
									return [installerobj];
								})
							);
					}
				}
			},
			navs: [
				{
					text: 'reupload photos',
                    serverCalls: {
                        modalWarning: {
                            directCall: (_http: HttpClient, store: Store, sq, bf: BigFormService, _ctrl: ManifestController<any>, _modal: ModalService  ) => {
                                _modal.openModalDirectly(instance => {
                                    instance.type = 'warning';
                                    instance.title = 'Attention';
                                    instance.setMessage(['If no photos are present, or if photos are incorrect Builders will not pay 4-Sure and 4-Sure', ' will claim the money back from you']); 
                                    instance.navButtons = [
                                        {
                                            text: 'continue',
                                            linkType: 'close',
                                        }
                                    ]
                                })
                                return of({});
                            },
                            errorMessage: 'Something went wrong with modal warning'
                        }
                    },
					nextNode: 'UploadMissingPhotos',
					color: 'secondary'
				}
			]
		},
		UploadMissingPhotos: {
			checkValidityForFields: ['fileQueue'],
			initFormFields: (_bf: BigFormService) => {
				_bf.bigForm.addControl('fileQueue', new UntypedFormControl([], [Validators.required, Validators.minLength(1)]));
				_bf.bigForm.addControl('purpose', new UntypedFormControl('builders_missing_photos', []));

				_bf.patchValues({ new_state: 27 }); //new_state: 26
			},
			
			component: 'FLXFileUploadWithPreviewComponent',
			inputs: {
				multipleUploads: false,
				purposes: [{ display: 'Missing photos', value: 'Missing photos' }],
				allowedFileTypes: ['pdf', 'image'],
				containerWidth: '50vw',
				maxWidth: '50vw'
			},
			
			navs: [
				{
					text: 'Done',
					serverFirst: true,
					nextNode: 'SubmissionSuccess',
					optIntoValidation: true,
					color: 'primary',
					linkType: 'portal', //submit option
					serverCalls: {
						uploadPhoto: {
							errorMessage: 'Missing photos could not be uploaded',
							directCall: (_http: HttpClient, store: Store, sq, bf: BigFormService) => {
								const fileQueue = bf.bigForm.get('fileQueue')?.value as Array<any>;
								const uploadPromises = [];
								for (let i = 0; i < fileQueue.length; i++) {
									const filename = fileQueue[i] ? fileQueue[i]?.file.nativeFile.name : '';
									const fileupload_request: object = {
										data: fileQueue[i] ? fileQueue[i]?.file.base64 : '',
										job_id: bf.bigForm.get('itemTwo')?.value?.id,
										purpose: bf.bigForm.get('purpose')?.value,
										filename: filename
									};
									const fileUploadPromise = _http
										.post(`${environment.api_url}v1/file_action/upload_file/`, fileupload_request, {
											observe: 'events',
											reportProgress: true
										})
										.toPromise();
									uploadPromises.push(fileUploadPromise);
								}
								const jobServerActions = [];

								
								
								jobServerActions.push(uploadPromises);
								
								const jobupdate_request = {
									job_id: bf.bigForm.get('itemTwo')?.value?.id,
									new_state: 27
								};
								const jobupdatepromise = _http.post(`${environment.api_url}v1/job_action/update_job`, jobupdate_request).toPromise();


								jobServerActions.push(jobupdatepromise);

								// return of({});

								return of(
									Promise.all(jobServerActions)
										.then(result => console.log('job server actions resolved', result))
										.catch(err => console.warn('PROMISE ERROR', err))
								);
							}
						}
					}
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state'
	}
};
