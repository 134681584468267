/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flow_0_0_2, getSelectedItemTwo, getCurrentUser, getFullItemTwo, getAllInfo } from '@flexus/core';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';

import { filter,  map, switchMap, pluck, take } from 'rxjs';
import { environment } from 'apps/studio/src/environments/environment';
import { forkJoin, of } from 'rxjs';
import { UntypedFormControl, Validators } from '@angular/forms';
import { JOB_INFO_NODE } from '../reusable/JOB_INFO_NODE';
import { HttpClient } from '@angular/common/http';
import { DecimalPipe } from '@angular/common';
import { Store } from '@ngrx/store';
import gql from 'graphql-tag';

export const PGG_21: Flow_0_0_2 = {
	id: '21',
	name: 'job_assigned_sp',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-details', 'notes', 'documents']),

	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store: any) => {
			return store.select(getFullItemTwo).pipe(
				map((itemTwo: any) => {
					if (itemTwo?.claim) {
						if (itemTwo?.claim?.applicant && itemTwo?.claim?.loan_information) {
							return `Job Awarded : ${itemTwo?.claim?.loan_information?.voucher_key} - ${itemTwo?.claim?.applicant?.first_name} `;
						} else {
							return 'Job Awarded - Allocate team leader';
						}
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {
		skills: {
			serviceVariable: 'spService',
			functionName: 'getSkills',
			responseSlice: 'skills',
			errorMessage: 'No skills were found!'
		},
		jobLocationData: {
			errorMessage: 'The job location was not returned!',
			serviceVariable: 'spService',
			functionName: 'getJobLocation',
			responseSlice: 'payload'
		},
		teamleaderLocations: {
			errorMessage: 'Team leader locations not returned!',
			directCall: (http) => {
				return http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
					pluck('payload'),
					filter((x: any) => !!x),
					map((teamleaders: any[]) => {
						return teamleaders;
					})
				);
			}
		}
	},

	instructions: {
		editRoles: {
			0: 'Allocate a team leader to job'
		},
		viewRoles: {
			0: 'Awaiting TL allocation'
		}
	},
	startNode: 'JobAllocationNotification',
	nodes: {
		JobAllocationNotification: {
			serverCalls: {
				voucherinfo: {
					errorMessage: `Couldn't get the voucher`,
					directCall: (_http: HttpClient, _store: Store) => {
						return _store
							.select(getFullItemTwo)
							.pipe(
								filter(Boolean),
								take(1)
							)
							.pipe(
								map((itemTwo: any) => {
									console.log('voucherinfo', itemTwo)
									const { claim } = itemTwo;
									const { job_information } = itemTwo;
									let additional_cost;
									let incentive;
									const additional_fees_amount = job_information?.additional_fees_amount;
									if (!additional_fees_amount) {
										additional_cost = parseFloat(new DecimalPipe('en-US').transform(Number(0), '1.2-2'));
									} else {
										// additional_cost = +additional_fees_amount;
										additional_cost = parseFloat(new DecimalPipe('en-US').transform(Number(additional_fees_amount), '1.2-2'));
									}
									const installer_incentive_amount = job_information?.installer_incentive_amount;
									if (!installer_incentive_amount) {
										incentive = parseFloat(new DecimalPipe('en-US').transform(Number(0), '1.2-2'));
									} else {
										// incentive = +installer_incentive_amount;
										incentive = parseFloat(new DecimalPipe('en-US').transform(Number(installer_incentive_amount), '1.2-2'));
									}
									const values = claim?.loan_information?.values;
									// const voucher_amount = values?.total_value;
									const sp_value = +values?.sp_value;
									const summed_invoice_amount: any = sp_value + incentive + additional_cost;
									const approved_sp_cost = new DecimalPipe('en-US').transform(Number(summed_invoice_amount), '1.2-2');
									const voucherobject = {
										// 'Voucher amount': `R ${voucher_amount}`,
										'Approved SP Cost (Excl. VAT)': `R ${approved_sp_cost}`
									};
									return [voucherobject];
								})
							);
					}
				},
				customerdetails: {
					errorMessage: 'No customer details were found!',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											claim {
												applicant {
													first_name
													surname
													contact_number
												}
												loan_information {
													onsitecontact
												}
											}
										}
									}
								`,
								store.select(getFullItemTwo).pipe(
									filter(Boolean),
									take(1),
									map(res => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((qdat: any) => {
									let firstname, surname, contact_number, cell_number;
									if (!qdat) {
										firstname = 'No first name available';
										surname = 'No surname available';
										contact_number = 'No contact number';
									} else {
										if (qdat.first_name === '') {
											firstname = 'No first name available';
										} else {
											firstname = qdat.first_name ?? 'No first name available';
										}
										if (qdat.first_name === '') {
											surname = 'No surname available';
										} else {
											surname = qdat?.surname ?? 'No surname available';
										}
										if (qdat.contact_number === '') {
											contact_number = 'No contact number';
										} else {
											contact_number = qdat?.contact_number ?? 'No contact number';
										}
										if (qdat.cellnumber === '') {
											cell_number = 'No cell number';
										} else {
											cell_number = qdat?.onsitecontact ?? 'No cell number supplied';
										}
									}
									return [
										{
											'Client Name': `${firstname} ${surname}`,
											'Contact Number': `${contact_number}`,
											'Mobile Number': `${cell_number}`
										}
									];
								})
							);
					}
				},
				installationsummary: {
					errorMessage: `Couldn't get installation summary`,
					directCall: (_http: HttpClient, _store: Store) => {
						return forkJoin([
							_store.select(getFullItemTwo).pipe(
								filter(Boolean),
								take(1)
							),
							_store.select(getAllInfo).pipe(
								filter(Boolean),
								take(1),
							)
						]).pipe(
							map(([itemtwo, estimates]: any) => {
								const voucher_number: string = itemtwo?.claim?.loan_information?.voucher_key ?? 'No voucher mumber';
								const product: string = itemtwo?.claim?.loan_information?.voucher_type ?? 'No product information';
								const skillid: number | 'No skill' = +itemtwo?.claim?.loan_information?.skill_id ?? 'No skill';
								const { estimate_installation_times } = estimates;
								const estimate: any = estimate_installation_times.find(el => el.skill === skillid)?.installation_time;
		
								const installationsummaryobject = {
									'Voucher number': `${voucher_number}`,
									Product: `${product}`,
									'Estimated installation completion': `${estimate} hours`
								};
								return [installationsummaryobject];
							})
						);
					}
				},
				installerdetails: {
					errorMessage: `Couldn't get installation summary`,
					directCall: (http, store) => {
						return store.select(getFullItemTwo).pipe(
							filter(Boolean),
							take(1),
							map((rest: any) => {
								const installer: string = rest?.team_leader?.full_name ?? 'No team leader assigned';
								const installernumber: string = rest?.team_leader?.contact_number ?? 'No contact number for team leader';
		
								const installerobject = {
									'Installer Name': `${installer}`,
									'Installer contact number': `${installernumber}`
									// 'Installer was on site': 'Yes',
									// 'Installer completed installation': `Yes`
								};
								return [installerobject];
							})
						);
					}
				}
			},
			...JOB_INFO_NODE(21),
			showTabs: true,
			name: 'Job Details',
			navs: [
				{
					text: 'Continue',
					linkType: 'portal',
					color: 'primary',
					nextNode: 'AssignTeamLeader',
					serverFirst: true
				}
			]
		},

		AssignTeamLeader: {
			name: 'Select Team Leader / Map',
			showTabs: true,
			checkValidityForFields: ['assign_teamleader_id'],
			initFormFields: bf => {
				bf.addControl('assign_teamleader_id', new UntypedFormControl('', Validators.required));
			},
			serverCalls: {
				tlListData: {
					errorMessage: '',
					directCall: (http:HttpClient) => {
						return http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
							pluck('payload'),
							filter((x: any) => !!x),
							map((teamleaders: any[]) =>
								teamleaders.map(teamLeader => {
									return {
										display: teamLeader.full_name,
										value: teamLeader.id,
										teamLeader,
										shouldHaveImage: true
									};
								})
							)
						);
					}
				}
			},
			component: 'AssignTLComponent',
			inputs: [
				{
					joblocation$: 'jobLocationData',
					teamleadersPositions$: 'teamleaderLocations'
				}
			],

			navs: [
				// {
				//   text: 'Update Map',
				//   nextNode: ''
				// },
				{
					text: 'Submit',
					nextNode: 'SubmissionSuccess',
					color: 'primary',
					optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: "Couldn't update job!",
							directCall: (http, store, sq, bf) => {
								return forkJoin([
									store.select(getSelectedItemTwo).pipe(
										filter(Boolean),
										take(1)
									),
									store.select(getCurrentUser).pipe(
										filter(Boolean),
										take(1)
										),
									of(bf.bigForm.get('assign_teamleader_id')?.value).pipe(
										filter(Boolean),
										take(1)
										)
								]).pipe(
									switchMap(([j, uid, lead]) => {
										console.log('TEAM LEADER', lead)
										const job = j as any;
										const user = uid as any;
										const job_id = job?.id;
										const staffmember = user.id;
										const team = lead[0];

										const data = {
											job_id: job_id,
											staffmember: staffmember,
											team_id: team
											// new_state: 22
										};
										return http.post(`${environment.api_url}v1/job_action/assign_team/`, data);
									})
								);
							}
						}
					},

					location: 'right'
				}
			]
		},
		SubmissionSuccess: {
			component: 'FLXSuccessTickComponent'
		}
	},
	bigFormToStoreMapper: {
		new_state: 'new_state',
		assign_teamleader_id: [
			[
				(tlid: any) => {
					if (tlid) {
						let id;
						tlid = tlid && Array.isArray(tlid) ? tlid : [];
						for (const lead of tlid) {
							id = parseInt(lead, 10);
							// console.log('TEAM LEAD ID', id);
						}
						return id;
					}
					return 0;
				},
				'job_information.team_leader'
			]
		]
	}
};
