/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { take, switchMap, map, pluck, skipWhile, filter, tap, catchError } from 'rxjs/operators';
import { combineLatest, Observable, from, forkJoin, empty, of, iif, throwError } from 'rxjs';
import moment from 'moment';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ClaimService } from './child-services/claim.service';
import { JobService } from './child-services/job.service';
import {
	getSelectedItemOne,
	getSelectedItemTwo,
	getAllInfo,
	getFullItemOne,
	BigFormService,
	getSubmissionData,
	getFullItemTwo,
	getCurrentUser,
	getAppointmentTypes,
	IndexedDbService,
	getData,
	ModalService,
	ManifestController,
	SetNextNode
} from '@flexus/core';
import { NewJobPayload } from '../models/new-job.model';
import { inlineRecursiveMerge, transformCallLog, findName, getAllInfoIndex } from '@flexus/utilities';
import { UUID } from 'angular2-uuid';
import { FlxLoaderService } from './flx-loader.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { BillingLineItem } from '@flexus/models';

@Injectable()
export class SpService {
	internalAssessorJobStates = [
		96, // internal_assessment
		97 // internal_assessor_job_report_uploaded
	];

	constructor(
		private store: Store<any>,
		private http: HttpClient,
		private claimService: ClaimService,
		private jobService: JobService,
		private bf: BigFormService,
		private indexedDbService: IndexedDbService,
		private modalsService: ModalService,
		private loaderService: FlxLoaderService,
		private _controller: ManifestController<any>
	) {}

	getSelectedItemOne() {
		return this.store.select(getSelectedItemOne).pipe(
			skipWhile(x => !x),
			take(1)
		);
	}

	getSelectedItemTwo() {
		return this.store.select(getSelectedItemTwo).pipe(
			skipWhile(x => !x),
			take(1)
		);
	}

	getJobSP() {
		return this.getSelectedItemTwo().pipe(
			pluck('sp'),
			switchMap(sp => {
				return this.getInterestedParties().pipe(
					pluck('payload'),
					map((interested_parties: any[]) => ({ ...interested_parties.find(ip => ip.sp.id === sp) }))
				);
			}),
			take(1)
		);
	}

	getAppointSPSummary() {
		return this.getJobSP().pipe(
			map(interestedParty => {
				let res;
				switch (interestedParty.new_interested) {
					case -1: {
						res = 'Rejected';
						break;
					}
					case 0: {
						res = 'Ignored';
						break;
					}
					case 1: {
						res = 'Accepted';
						break;
					}
					case null: {
						res = 'No-Reply';
						break;
					}
				}
				return [
					{ 'Primary Contact': interestedParty.sp.contact_person },
					{ 'Primary Contact Number': interestedParty.sp.contact_primary },
					{ maven_id: interestedParty.sp.mid !== null ? interestedParty.sp.mid : 'Not On Maven' },
					{
						'response to job': res
					},
					{ 'Currently Awarded Jobs': interestedParty.awarded_today }
				];
			}),
			take(1)
		);
	}

	appointSP() {
		return this.getSelectedItemTwo().pipe(
			switchMap(jobItem => {
				return this.jobService.manuallyAllocateJob({ jobId: jobItem.id, spId: jobItem.sp });
			})
		);
	}

	repingSP() {
		const appointment = this.bf.bigForm.get('appointmentData')?.value;
		const date = moment(appointment.appointmentDatePicker);
		date.hour(appointment.appointmentTimePicker.hour);
		date.minutes(appointment.appointmentTimePicker.minutes);

		const date_formatted = date.format('YYYY-MM-DDTHH:mm:ss');
		const appointment_formatted = [{ range_start: date_formatted, range_end: date_formatted, appointment_type_id: appointment.appointmentTime }];

		return this.getSelectedItemTwo().pipe(
			pluck('id'),
			switchMap(job_id => {
				return this.jobService.repingJob({ appointments: appointment_formatted, job_id: job_id });
			})
		);
	}

	getAllClaims() {
		return this.claimService.getAllClaims();
	}

	getClaimAppointments() {
		return this.getSelectedItemOne().pipe(
			switchMap(claim => {
				return this.claimService.getClaimAppointments({ claim_id: claim?.id });
			})
		);
	}

	getCurrentFullItemOne() {
		return this.getSelectedItemOne().pipe(
			take(1),
			switchMap(item => this.getFullItemOne(item?.id))
		);
	}

	getFullItemOne(id) {
		return this.claimService.getFullClaim(id);
		// return this.getSelectedItemOne().pipe(
		//   switchMap((claim) => {
		//   }),
		// );
	}

	getFullItemOneWithItemTwo(id) {
		return this.claimService.getFullClaimWithJobs(id);
	}

	getFullItemTwo(id) {
		return this.jobService.getFullJob(id);
		// return this.getSelectedItemTwo().pipe(
		//   switchMap((job) => {
		//   }),
		// );
	}

	getInterestedParties() {
		return this.getSelectedItemTwo().pipe(
			skipWhile(x => !x),
			pluck('id'),
			switchMap(jobId => {
				return this.jobService.getInterestedParties({ job_id: jobId });
			})
		);
	}

	dealOutOfApp() {
		return this.getSelectedItemTwo().pipe(switchMap(itemTwo => this.jobService.updateJob({ job_id: itemTwo?.id, new_state: 76 })));
	}

	cancelJob() {
		return this.getSelectedItemTwo().pipe(
			switchMap(job => {
				return this.jobService.cancelJob({ job_id: job?.id, reason: this.bf.bigForm?.value?.cancelclaim_reason });
			})
		);
	}

	newJob(jobData: NewJobPayload) {
		return this.jobService.newJob(jobData);

		// return forkJoin([
		//   this.store.select(getSubmissionData).pipe(take(1)),
		//   this.store.select(getFullItemOne).pipe(take(1)),
		//   this.store.select(getFullItemTwo).pipe(take(1)),
		//   this.store.select(getAllInfo).pipe(
		//     take(1),
		//     pluck('skills'),
		//   ),
		//   this.store.select(getAllInfo).pipe(
		//     take(1),
		//     pluck('appointment_types'),
		//   ),
		// ]).pipe(
		//   map(([submissionData, claim, job, skills, appointment_types]) => {
		//     const appointmentDetails = submissionData.new_job_data;
		//     const jobData = {};

		//     const { jobcardrequest, selectedskills } = claim?.loan_information;
		//     let skillObject = {};
		//     const { job_information } = job;

		//     if (job_information.skills_needed !== undefined) {
		//       jobData = {
		//         ...NewJobUtilities.getSkillObjectByProperty(skills, 'name', job_information.skills_needed.replace(/,/gi, '')),
		//         jobData
		//       };

		//       jobData['skillrequested'] = job?.office_use.skillrequested;
		//       jobData['skillcatagory'] = job?.office_use.skillcatagory;
		//     }

		//     let newJobCardRequest = [];
		//     if (Array.isArray(jobcardrequest)) {
		//       newJobCardRequest = [...jobcardrequest, jobData];
		//     } else {
		//       newJobCardRequest = [jobcardrequest, jobData];
		//     }

		//     let newSelectedSkills = [];
		//     if (Array.isArray(selectedskills)) {
		//       newSelectedSkills = [...selectedskills, { category: jobData['skillcatagory'], subcategory: jobData['skill'] }];
		//     } else {
		//       newSelectedSkills = [selectedskills, { category: jobData['skillcatagory'], subcategory: jobData['skill'] }];
		//     }

		//     const appointment_types_array = appointment_types as any[];

		//     const toReturn = {
		//       claim: claim?.id,
		//       area: job?.area,
		//       application: { ...claim.loan_information, jobcardrequest: newJobCardRequest, selectedskills: newSelectedSkills },
		//       jobcardrequest: [
		//         {
		//           ...jobData,
		//           appointment_type: appointmentDetails.appointmentTime
		//             ? appointment_types_array.find((type) => type.id === appointmentDetails.appointmentTime).name
		//             : null,
		//           appointment_type_id: appointmentDetails.appointmentTime ? appointmentDetails.appointmentTime : null,
		//           appointmentdatetype: appointmentDetails.appointmentDateType,
		//           providertype: job?.office_use.providertype,
		//           providertype_id: job?.office_use.providertype_id,
		//           requestedappointmentdate: appointmentDetails.appointmentDatePicker
		//             ? moment(appointmentDetails.appointmentDatePicker)?.format(moment.HTML5_FMT.DATE)
		//             : null,
		//           requestedappointmenttime: appointmentDetails.appointmentTimePicker
		//             ? `${appointmentDetails.appointmentTimePicker.hour}:${appointmentDetails.appointmentTimePicker.minutes}:00`
		//             : null,
		//         },
		//       ],
		//     };

		//     return toReturn;
		//   }),
		//   switchMap((data) => {
		//     return this.jobService.newJob(data);
		//   }),
		// );
	}

	assessorVisitSite() {
		return forkJoin([
			this.getSelectedItemTwo().pipe(
				skipWhile(x => !x),
				take(1),
				map(res => res as any)
			),
			this.store.select(getAllInfo).pipe(
				skipWhile(x => !x),
				take(1),
				pluck('appointment_types'),
				map(res => res as any[])
			)
		]).pipe(
			switchMap(([job, appointment_types]) => {
				let new_state;
				let return_to_state;

				switch (job.state) {
					case 106:
					case 32:
						new_state = job?.state;
						return_to_state = 90;
						break;
					case 90:
						new_state = 90;
						return_to_state = job?.job_information?.return_to_state;
						break;
					default:
						new_state = 90;
						return_to_state = job?.state;
				}

				const appointmentData: any = this.bf.bigForm.get('appointmentData')?.value;
				const date = appointmentData.appointmentDatePicker ? moment(appointmentData.appointmentDatePicker) : null;

				const range_start = moment(
					new Date(date.year(), date.month(), date.date(), appointmentData.appointmentTimePicker.hour, appointmentData.appointmentTimePicker.minutes)
				).format('YYYY-MM-DDTHH:mm:ss');

				const data = {
					appointment: {
						appointment_type: appointmentData.appointmentTime ? appointment_types.find(type => type.id === appointmentData.appointmentTime).id : null,
						range_start: range_start,
						reason: ''
					},
					current_state: job?.state,
					job_id: job?.id,
					job_information: {
						return_to_state: return_to_state
					},
					new_state: new_state
				};

				return this.jobService.updateJob(data);
			})
		);
	}

	updateUnmanagedMavenJob() {
		return forkJoin([
			this.store.select(getSubmissionData).pipe(
				skipWhile(x => !x || !x.job_information),
				take(1)
			),
			this.store.select(getFullItemTwo).pipe(take(1))
		]).pipe(
			map(([submissionData, job]) => {
				const job_information = { ...job.job_information, ...submissionData.job_information };
				if (!job_information.OutAppFlowSPid && !job_information.OutAppFlowSPMID) {
					job_information.OutAppFlowSPid = 0;
					job_information.OutAppFlowSPMID = 0;
				}
				return { job_id: job?.id, new_state: submissionData.new_state, job_information: job_information };
			}),
			switchMap(data => {
				return this.jobService.updateJob(data);
			})
		);
	}

	generateBOQReport() {
		return this.store
			.select(getFullItemTwo)
			.pipe(take(1))
			.pipe(
				switchMap(response => {
					const { id } = response;
					return this.jobService.generateBOQReport(id);
				})
			);
	}

	updateJob() {
		return forkJoin([this.store.select(getSubmissionData).pipe(take(1)), this.store.select(getFullItemTwo).pipe(take(1))]).pipe(
			map(([submissionData, job]) => {
				const { job_information } = job;
				const result = JSON.parse(JSON.stringify(job_information ?? {}));
				let response: any = {
					job_information: result
				};
				if (submissionData) {
					const { job_information: ji, ...rest } = submissionData;
					inlineRecursiveMerge(result, ji);
					response = { ...response, ...rest };
				}
				response = { ...response, job };
				return response;
			}),
			switchMap(response => {
				const { job, ...data } = response;
				return this.jobService.updateJob({ job_id: job?.id, ...data });
			})
		);
		// return this.store.select(getSubmissionData).pipe(
		//   take(1),
		//   switchMap((serverData) => {
		//     return this.getSelectedItemTwo().pipe(
		//       switchMap((job) => {
		//         return this.jobService.updateJob({ job_id: job?.id, ...serverData });
		//       }),
		//     );
		//   }),
		// );
	}

	updateClaim() {
		return forkJoin(this.store.select(getSubmissionData).pipe(take(1)), this.store.select(getFullItemOne).pipe(take(1))).pipe(
			map(([submissionData, claim]) => {
				const { loan_information } = claim;
				const { loan_information: ci, ...rest } = submissionData;
				const result = JSON.parse(JSON.stringify(loan_information));
				inlineRecursiveMerge(result, ci);

				const response = {
					loan_information: result,
					...rest,
					claim
				};
				return response;
			}),
			switchMap(response => {
				const { claim, ...data } = response;
				return this.claimService.updateClaim({ claim_id: claim?.id, ...data });
			})
		);
		// return this.store.select(getSubmissionData).pipe(
		//   take(1),
		//   switchMap((serverData) => {
		//     return this.getSelectedItemOne().pipe(
		//       switchMap((claim) => {
		//         return this.claimService.updateClaim({ claim_id: claim?.id, ...serverData });
		//       }),
		//     );
		//   }),
		// );
	}

	createClaim() {
		return this.store.select(getSubmissionData).pipe(
			take(1),
			switchMap(claim => {
				return this.claimService.createClaim({ ...claim });
			})
		);
	}

	cancelClaim() {
		return this.getSelectedItemOne().pipe(
			switchMap(claim => {
				return this.claimService.cancelClaim({
					claim_id: claim?.id,
					reason: this.bf.bigForm?.value?.cancel_reason
				});
				// return this.claimService.cancelClaim({claim_id: claim?.id, reason: this.bf.bigForm.value.cancel_reason})
			})
		);
	}

	reopenClaim() {
		return this.store
			.select(getFullItemOne)
			.pipe(
				skipWhile(x => !x),
				take(1),
				map(res => res as any)
			)
			.pipe(
				map(claim => {
					const claim_id = (claim as any).id;
					const reason = this.bf.bigForm.get('reopen_data');

					return {
						claim_id: claim_id,
						reopen_code: reason?.value?.reopen_reason
					};
				}),
				switchMap(response => {
					return this.claimService.reopenClaim(response);
				})
			);
	}

	saveAsServerDraft() {
		return this.store.select(getSubmissionData).pipe(
			take(1),
			switchMap(claim => {
				return this.claimService.createClaim({ ...claim, new_state: 8 });
				// return throwError({ success: false, payload: '' });
			})
			// catchError((error) => {
			//   return of(error);
			// }),
		);
	}

	checkNetworkAndUpdateDraft() {
		if (window.navigator.onLine) {
			// update server draft
			return this.saveAsServerDraft();
		} else {
			return this.saveAsLocalDraft();
		}
	}

	updateServerDraft(new_state) {
		return forkJoin(this.store.select(getSubmissionData).pipe(take(1)), this.store.select(getFullItemOne).pipe(take(1))).pipe(
			map(([submissionData, claim]) => {
				const { loan_information } = claim;
				const { loan_information: ci, ...rest } = submissionData;
				const result = JSON.parse(JSON.stringify(loan_information));
				inlineRecursiveMerge(result, ci);

				const response = {
					loan_information: result,
					...rest,
					claim
				};
				return response;
			}),
			switchMap(response => {
				const { claim, ...data } = response;
				return this.claimService.updateClaim({ claim_id: claim?.id, ...data, new_state });
			})
		);
	}

	updateServerDraftAsSubmitted() {
		return forkJoin(this.store.select(getSubmissionData).pipe(take(1)), this.store.select(getFullItemOne).pipe(take(1))).pipe(
			map(([submissionData, claim]) => {
				const { loan_information } = claim;
				const { loan_information: ci, ...rest } = submissionData;
				const result = JSON.parse(JSON.stringify(loan_information));
				inlineRecursiveMerge(result, ci);

				const response = {
					loan_information: result,
					...rest,
					claim
				};
				return response;
			}),
			switchMap(response => {
				const { claim, ...data } = response;
				return this.claimService.updateClaim({ claim_id: claim?.id, ...data, new_state: 1 });
			})
		);
	}

	saveAsLocalDraft() {
		return this.store.select(getSubmissionData).pipe(
			take(1),
			switchMap(item => {
				const localDraft = { tempKey: UUID.UUID(), ...item, state: 169 };
				return this.indexedDbService.claimInDraft.put(localDraft);
			})
		);
	}

	checkNetworkAndSaveDraft() {
		if (window.navigator.onLine) {
			return this.saveAsServerDraft();
		} else {
			return this.saveAsLocalDraft();
		}
	}

	deleteCurrentEditableItem() {
		return from(this.indexedDbService.currentItem.delete('currentItem')).pipe(map(res => ({ success: true, payload: res })));
	}

	deleteLocalDraft() {
		return this.getSelectedItemOne().pipe(switchMap(itemOne => from(this.indexedDbService.claimInDraft.delete(itemOne.tempKey))));
	}

	policyLookup(data: { search: string }) {
		return this.http.post(`${environment.api_url}v1/claim_action/get_policies/`, {
			search: data?.search
		});
	}

	policyHistorySearch(data: { search: string }) {
		return this.http.post(`${environment.api_url}v1/claim_action/get_claim_history/`, {
			search: data?.search
		});
	}

	getItemOneExtra() {
		return this.getSelectedItemOne().pipe(
			switchMap(claim => {
				return this.claimService.getClaimExtra(claim?.id);
			})
		);
	}

	getCloseClaimInfo() {
		return this.getSelectedItemOne().pipe(
			switchMap(claim => {
				return this.getCloseClaimInfoAPI({ claim_id: claim?.id }).pipe(
					map(res => {
						return res;
					})
				);
			})
		);
	}

	// getJobInvoice() {
	//   return this.getSelectedItemTwo().pipe(
	//     switchMap((item) => {
	//       return this.paymentService.getJobInvoice(item.id).pipe(map((res: any) => res?.payload));
	//     }),
	//   );
	// }

	getSkills() {
		return this.store.select(getAllInfo).pipe(
			skipWhile((allInfo: any) => {
				return allInfo.states === null || allInfo.states[1] === undefined;
			})
		);
	}

	getAppointmentTypes() {
		return this.store.select(getAppointmentTypes).pipe(
			skipWhile((appointmentTypes: any) => {
				return appointmentTypes === null || appointmentTypes === undefined;
			})
		);
	}

	getActiveReions() {
		return this.store.select(this.getActiveReions).pipe(
			skipWhile((activeReions: any) => {
				return activeReions === null || activeReions === undefined;
			})
		);
	}

	getJobInvoice() {
		return this.getSelectedItemTwo().pipe(
			skipWhile(x => !x),
			take(1),
			switchMap((itemTwo: any) => {
				return this.http
					.post<any>(environment.api_url + `v1/file_action/get_invoice/`, {
						job_id: itemTwo?.id,
						return_type: 1
					})
					.pipe(
						map(payload => {
							return payload.payload;
						})
					);
			})
		);
	}

	getJobReport() {
		return this.getSelectedItemTwo().pipe(
			skipWhile(x => !x),
			take(1),
			switchMap((itemTwo: any) => {
				return this.http
					.post<any>(environment.api_url + `v1/file_action/get_invoice/`, {
						job_id: itemTwo?.id,
						return_type: 1
					})
					.pipe(map(payload => payload.payload));
			})
		);
	}

	getServiceProvidersOnClaim() {
		return combineLatest(this.getSelectedItemOne(), this.store.select(getAllInfo).pipe(map((allInfo: any) => allInfo.sps))).pipe(
			map(([claim, serviceProviders]) => {
				const jobs = claim?.jobs.filter(job => job?.state !== 45 || job?.state !== 72);
				return jobs
					.filter(job => !!job.sp)
					.map(job => ({
						jobId: job?.id,
						skill: job?.skill,
						spName: findName(job.sp, serviceProviders).name
					}));
			})
		);
	}

	getClaimMid() {
		return this.store.select(getFullItemOne).pipe(
			skipWhile(x => !x),
			pluck('mid'),
			take(1),
			map(data => {
				return data;
			})
		);
	}

	getPaymentAuthorizationList() {
		// return this.store.select(getFullItemTwo).pipe(
		//   switchMap((item) => {
		//     return this.paymentService.getPaymentAuthorizationList().pipe(
		//       map((data: any) => data.payload),
		//       map((persons: any) => {
		//         switch (item.state) {
		//           // case 27: {
		//           //   return persons.filter((p) => p.max_auth >= activeFlowJobInfo.claim_value);
		//           // }
		//           case 50:
		//           case 51: {
		//             return persons.filter((p) => p.max_auth >= item.claim_value);
		//           }
		//           default: {
		//             return persons;
		//           }
		//         }
		//       }),
		//     );
		//   }),
		// );
		// return combineLatest(
		//   this.store.select(getActiveFlowItemData).pipe(map((data) => data.tieredInformation.job.job_information)),
		//   this.store.select(getFullItemTwo),
		// ).pipe(
		//   takeWhile((value) => !!value && !!value[0] && !!value[1] && !!value[2]),
		//   map((value) => {
		//     const persons = value[0].payload;
		//     const activeFlowJobInfo = value[1];
		//     const fullJob = value[2];
		//     switch (fullJob.state) {
		//       case 27: {
		//         return persons.filter((p) => p.max_auth >= activeFlowJobInfo.claim_value);
		//       }
		//       case 50:
		//       case 51: {
		//         return persons.filter((p) => p.max_auth >= fullJob.claim_value);
		//       }
		//       default: {
		//         return persons;
		//       }
		//     }
		//   }),
		// );
	}

	getAllClaimFiles() {
		return this.getSelectedItemOne().pipe(
			switchMap(itemOne => {
				return this.getAllFilesApi(null, itemOne?.id, null);
			})
		);
	}

	getAllJobFiles() {
		return this.getSelectedItemTwo().pipe(
			switchMap(itemTwo => {
				if (itemTwo) {
					return this.getAllFilesApi(itemTwo.id, null);
				} else {
					return empty();
				}
			})
		);
	}

	getNotes() {
		return combineLatest([this.getSelectedItemTwo(), this.getInternalAssessorJob()]).pipe(
			switchMap(([itemTwo, iaJob]: any) => {
				if (itemTwo && iaJob && itemTwo?.id === iaJob.id) {
					return this.getjobAssessmentNotes();
				} else {
					return this.getJobNotes();
				}
			})
		);
	}

	getJobNotes() {
		return this.getSelectedItemTwo().pipe(
			switchMap(itemTwo => {
				if (itemTwo) {
					return this.getJobNotesApi(itemTwo.id);
				} else {
					return empty();
				}
			})
		);
	}

	getjobAssessmentNotes() {
		return this.getSelectedItemTwo().pipe(
			switchMap(itemTwo => {
				return this.getjobAssessmentNotesApi(itemTwo.id).pipe(
					skipWhile(x => !x),
					pluck('payload'),
					map(iaNotes => iaNotes.map(iaNote => ({ timestamp: iaNote.created, message: iaNote.note })))
				);
			})
		);
	}

	getInternalAssessorJob() {
		return this.store.select(getSelectedItemOne).pipe(
			take(1),
			map(itemOne => {
				if (itemOne) {
					const iaJob = this.getinternalAssessorJobCard(itemOne.jobs);
					return iaJob ? iaJob : { id: 0 };
				}
			})
		);
	}

	// getPolicyHistory() {
	//   return this.policyHistoryLookup({ search: this.bf.bigForm.value.policy_number }).pipe(
	//     pluck('payload', 'claims'),
	//     map((claims) => {
	//       if (claims) {
	//         return (claims as any[]).map((claim) => [
	//           { title: 'Loss Date', value: this.formatDate(claim?.LOSSDATE) },
	//           { title: 'Claim Type', value: claim?.CLAIMTYPE },
	//           [{ title: 'Status', value: claim?.STATUS }, { title: 'Claim Cost', value: `R${claim?.CLAIMCOST}` }],
	//           { title: 'Comment', value: claim?.COMMENTS },
	//         ]);
	//       }
	//       return claims;
	//     }),
	//     map((claims: any[]) => {
	//       if (claims) {
	//         return { claims: claims.sort((a, b) => moment.utc(b.date.value)?.diff(a.date.value)) };
	//       }
	//       return { claims: claims };
	//     }),
	//     take(1),
	//     map((res: any) => res?.claims),
	//   );
	// }

	getPolicyHistory() {
		return this.store.select(getSelectedItemOne).pipe(
			skipWhile(x => !x),
			take(1),
			switchMap(itemOne => {
				if (itemOne) {
					return this.policyHistoryLookup({
						claim_id: itemOne?.id,
						search: this.bf.bigForm?.value?.policy_number
					}).pipe(
						pluck('payload', 'claims'),
						map(claims => {
							if (claims) {
								return (claims as any[]).map(claim => {
									const obj = {
										date: { title: 'Loss Date', value: this.formatDate(claim?.LOSSDATE) },
										eventType: { title: 'Claim Type', value: claim?.CLAIMTYPE },
										additionalItems: [
											{ title: 'Status', value: claim?.STATUS },
											{ title: 'Claim Cost', value: `R${claim?.CLAIMCOST}` }
										],
										description: { title: 'Comment', value: claim?.COMMENTS }
									};
									return obj;
								});
							}
							return claims;
						}),
						map((claims: any[]) => {
							if (claims) {
								return { claims: claims.sort((a, b) => moment.utc(b.date.value)?.diff(a.date.value)) };
							}
							return { claims: claims };
						}),
						take(1)
					);
				} else {
					return empty();
				}
			}),
			map(res => res?.claims)
		);
	}

	formatDate(date: string): Date {
		return moment(date, 'MM-D-YYYY h mm ss a').toDate();
	}

	// TODO CLAIM SERVICES

	getCloseClaimInfoAPI(params: any): Observable<any> {
		return this.http.post(environment.api_url + `v1/claim_action/can_finalise_claim/`, params);
	}

	finaliseClaim(params: { claim_id: number }): Observable<any> {
		return this.http.post(environment.api_url + `v1/claim_action/finalise_claim/`, params);
	}

	policyHistoryLookup(data: { claim_id: string; search: string }) {
		return this.http.post(`${environment.api_url}v1/claim_action/get_claim_history/`, {
			search: data?.search || '',
			claim_id: data?.claim_id
		});
	}

	searchClaims(params: { search: string; active: boolean }) {
		return this.http.post<any>(environment.api_url + `v1/claim_action/search_claims/`, { ...params });
	}

	checkCatastrophe(param: { claim_type: number; postal_code: number; loss_date: string }) {
		return this.http.post(environment.api_url + `v1/cat_action/check_catastrophe/`, param);
	}

	getExcessDetailsService(data: { claim_id: number }) {
		return this.http.post<any>(`${environment.api_url}v1/claim_action/get_excess_details/`, data);
	}

	// INTERNAL ASSESSOR SERVICES

	isInternalAssessorState(state: number) {
		return this.internalAssessorJobStates.includes(state);
	}

	getinternalAssessorJobCard(jobs: any[]) {
		return jobs.find(j => [89, 90, 28, 88, 37, 97]?.includes(j.state));
	}

	internalAssessorJobHasReport(state: number) {
		return state === 97; // internal_assessor_job_report_uploaded;
	}

	getAllFilesApi(jobId?: number, claimId?: number, purpose?: string) {
		const data: any = {};
		if (jobId) {
			data.job_id = jobId;
		}

		if (claimId) {
			data.claim_id = claimId;
		}

		if (purpose) {
			data.purpose = purpose;
		}

		return this.http.post<any>(environment.api_url + 'v1/file_action/get_files', data);
	}

	addAssessmentNoteApi() {
		return this.getSelectedItemTwo().pipe(
			switchMap(itemTwo =>
				this.http.post<any>(environment.api_url + 'v1/job_action/add_assessment_note', {
					job_id: itemTwo?.id,
					note: this.bf.bigForm.get('ia_message')?.value
				})
			)
		);
	}

	getjobAssessmentNotesApi(job_id: number) {
		const data = { job_id, job_only: true };
		return this.http.post<any>(environment.api_url + 'v1/job_action/get_assessment_notes', data);
	}

	getAssessmentNotesForAllJobsApi(job_id: number) {
		const data = { job_id, job_only: false };
		return this.http.post<any>(environment.api_url + 'v1/job_action/get_assessment_notes', data);
	}

	getJobNotesApi(job_id: number) {
		const data = { job_id };
		return this.http.post<any>(environment.api_url + 'v1/job_action/get_notes', data);
	}

	getAuthorizers() {
		return this.http.get(environment.api_url + 'v1/staff_action/get_authorizers').pipe(map(response => response['payload']));
	}

	// ----------------------------- AVS and Branch look up services -----------------------------
	getAvsCheckSIL(avsData: any) {
		return this.http.post<any>(`${environment.api_url}v1/claim_action/check_account_details/`, avsData);
	}

	getBranchLookupResults(params: { bank: string; branch: string }) {
		return this.http.post<any>(`${environment.api_url}v1/claim_action/get_branch_code/`, { ...params });
	}

	getSPReplyListData() {
		return forkJoin([
			this.store.select(getSelectedItemTwo).pipe(
				skipWhile(x => !x),
				take(1)
			),
			this.store.select(getCurrentUser).pipe(
				skipWhile(x => !x),
				take(1)
			),
			this.store.select(getAllInfo).pipe(
				take(1),
				map((allInfo: any) => allInfo.sps),
				map((sps: any[]) => sps.reduce((acc, sp: any) => ({ ...acc, [sp.id]: sp }), {}))
			),
			this.store.select(getSelectedItemTwo).pipe(
				skipWhile(x => !x),
				pluck('id'),
				take(1),
				switchMap(jobId =>
					this.http.post(`${environment.api_url}v1/job_action/get_interested_parties/`, {
						job_id: jobId
					})
				),
				map((res: any) => res?.payload)
			)
		]).pipe(
			map(([job, user, sps, parties]: any) => {
				if (!!job && !!user && !!sps && !!parties) {
					const isTeamLeader = user?.user?.is_team_leader === true;
					const templateTitle = `The Currently assigned SP is : ${job.sp ? sps[job.sp]?.name : 'none'}`;
					const listConfig = { alwaysEmitEvent: true };

					const entityCardState = {
						// info: ' entity-card--info',
						// warning: 'entity-card--warning',
						'-1': { state: 'entity-card--danger', blurb: 'Declined' },
						1: { state: 'entity-card--success', blurb: 'Accepted' },
						null: { state: 'entity-card--default', blurb: 'No-Reply' },
						0: { state: 'entity-card--default', blurb: 'Ignored' },
						currentSP: { state: 'entity-card--info', blurb: 'Current SP' }
					};
					const responseOrderWeightMap = {
						'No-Reply': 3,
						Accepted: 2,
						Ignored: 4,
						Declined: 5,
						'Current SP': 0
					};

					const data = parties
						.map((party: any) => {
							let lookupValue = party.new_interested;
							lookupValue = job && job?.sp === party.sp.id ? 'currentSP' : lookupValue;

							const cardStateInfo = entityCardState[lookupValue];
							return {
								entityId: party.sp.id,
								cardStateInfo,
								columns: [
									// col1 ~ sp details
									{
										title: party.sp.name,
										subTitle: party.sp.contact_person,
										description: party.sp.contact_primary
									},
									// col2 ~ need to know
									{
										title: cardStateInfo.blurb,
										subTitle: `${party.sp.mid ? 'On Maven' : 'Not on Maven'}`,
										description: `Number of assignments: ${party.awarded_today}`
									}
								]
							};
						})
						.sort((a, b) => responseOrderWeightMap[a.cardStateInfo.blurb] - responseOrderWeightMap[b.cardStateInfo.blurb]);
					return {
						isTeamLeader,
						templateTitle,
						listConfig,
						cardData: data || [],
						loadingMessage: 'loading',
						noCardsMessage: 'There are no records to display'
					};
				}
				return {
					templateTitle: '',
					cardData: []
				};
			})
		);
	}

	retryOnMaven() {
		return this.getSelectedItemTwo().pipe(
			switchMap(itemTwo => {
				return this.http.post(`${environment.api_url}v1/job_action/retry_upload_invoice_to_maven/`, { job_id: itemTwo?.id }).pipe(
					filter(x => !!x),
					take(1)
				);
			})
		);
	}

	getCallLogs() {
		return this.getSelectedItemOne().pipe(
			switchMap(itemOne => {
				return this.http.post<any>(`${environment.api_url}v1/claim_action/get_call_logs/`, { claim_id: itemOne?.id }).pipe(
					filter(x => !!x),
					map((data: any) => {
						const arr = [];
						data?.payload.forEach(log => {
							const formattedLog = transformCallLog(log);
							arr.push(formattedLog);
						});

						return arr;
					})
				);
			})
		);
	}

	addCallLog() {
		return this.getSelectedItemOne().pipe(
			switchMap(itemOne => {
				const data = {
					claim_id: itemOne?.id,
					message: this.bf.bigForm.get('message')?.value,
					channel: this.bf.bigForm.get('channel')?.value,
					reason: this.bf.bigForm.get('reason')?.value,
					direction: this.bf.bigForm.get('direction')?.value
				};
				return this.http.post(`${environment.api_url}v1/claim_action/add_call_log/`, { ...data }).pipe(
					switchMap((x: any) => {
						if (x.success) {
							const addedLog = transformCallLog(x.payload);

							this.bf.bigForm.patchValue({
								message: '',
								channel: null,
								reason: null,
								direction: null
							});

							return this.store.select(getData).pipe(
								map((contextData: any) => [...[addedLog], ...contextData.logs]),
								take(1)
							);
						}
					})
				);
			})
		);
	}

	getClaimNotes() {
		return this.getSelectedItemOne().pipe(
			switchMap(itemOne => {
				return this.getClaimNotesApi(itemOne.id);
			})
		);
	}

	getClaimNotesApi(claim_id: number) {
		const data = { claim_id };
		return this.http.post<any>(environment.api_url + 'v1/claim_action/get_notes', data).pipe(
			filter(x => !!x),
			map((notes: any) => {
				const arr = [];
				notes.payload.forEach(note => {
					note.date = moment(note['timestamp']).format(moment.HTML5_FMT.DATE);
					note.time = moment(note['timestamp']).format(moment.HTML5_FMT.TIME);
					arr.push(note);
				});
				return arr;
			})
		);
	}

	addJobNote() {
		return this.getSelectedItemTwo().pipe(
			switchMap(itemTwo => {
				const data = {
					job_id: itemTwo?.id,
					message: this.bf.bigForm.get('message')?.value
				};
				return this.http.post(`${environment.api_url}v1/job_action/add_note/`, { ...data }).pipe(map(response => response['payload']));
			})
		);
	}

	addClaimNote() {
		return this.getSelectedItemOne().pipe(
			switchMap(itemOne => {
				const data = {
					claim_id: itemOne?.id,
					message: this.bf.bigForm.get('message')?.value
				};

				return this.http.post(`${environment.api_url}v1/claim_action/add_note/`, { ...data }).pipe(map(response => response['payload']));
			})
		);
	}

	updateJobNewState() {
		return forkJoin([
			this.getSelectedItemTwo().pipe(
				pluck('id'),
				skipWhile(x => !x),
				take(1)
			),
			this.store.select(getSubmissionData).pipe(
				pluck('new_state'),
				skipWhile(x => !x),
				take(1)
			)
		]).pipe(
			switchMap(([job_id, new_state]) => {
				return this.jobService.updateJob({ job_id: job_id, new_state: new_state });
			})
		);
	}

	changeJobAppointmentFromContextMenu() {
		const appointment = this.bf.bigForm.get('appointmentData')?.value;
		let range_start: moment.Moment;
		let to_time: moment.Moment;

		if (appointment.appointmentTime === 3) {
			range_start = moment(appointment.appointmentDatePicker).set({
				hour: appointment.from_time.hour,
				minute: appointment.from_time.minutes
			});

			to_time = moment(appointment.appointmentDatePicker).set({
				hour: appointment.to_time.hour,
				minute: appointment.to_time.minutes
			});
		} else {
			range_start = moment(appointment.appointmentTimePicker).set({
				hour: appointment.appointmentTimePicker.hour,
				minute: appointment.appointmentTimePicker.minutes
			});
		}

		const appointment_formatted: any = {
			range_start: range_start.format('YYYY-MM-DDTHH:mm:ss'),
			appointment_type: appointment.appointmentTime,
			reason: ''
		};

		if (to_time) {
			appointment_formatted.from_time = range_start.format('YYYY-MM-DDTHH:mm:ss');
			appointment_formatted.to_time = to_time.format('YYYY-MM-DDTHH:mm:ss');
		}

		return forkJoin([this.store.pipe(take(1)), this.store.select(getFullItemTwo).pipe(take(1))]).pipe(
			switchMap(([store, fullItemTwo]) => {
				const office_use = {
					...fullItemTwo.office_use,
					appointment_type: appointment.appointmentTime ? getAllInfoIndex('appointment_types', 'id', 'name', appointment.appointmentTime, store) : null,
					appointment_type_id: appointment.appointmentTime,
					appointmentdatetype: appointment.appointmentDateType,
					requestedappointmentdate: moment(appointment_formatted.range_start).format('YYYY-MM-DD'),
					requestedappointmenttime: moment(appointment_formatted.range_start).format('HH:mm')
				};

				return this.jobService
					.changeAppointment({
						appointment: appointment_formatted,
						job_id: fullItemTwo.id,
						job_information: { ...fullItemTwo.job_information, return_to_state: fullItemTwo.state },
						office_use
					})
					.pipe(take(1));
			})
		);
	}

	recommendCil() {
		return this.store
			.select(getSubmissionData)
			.pipe(take(1))
			.pipe(
				switchMap(submissionData => {
					const { cil_state, effected_jobs, job_id } = submissionData;
					const data = { cil_state: environment.client.includes('bet') ? 56 : cil_state, effected_jobs, job_id };
					return this.http.post<any>(environment.api_url + 'v1/job_action/move_to_cil/', data);
				})
			);
	}

	createOrUpdateBOQ() {
		this.loaderService.setLoading(true);
		const hasBoq = this.bf.bigForm.get('hasBoq')?.value;
		const job = this.bf.bigForm.get('itemTwo').value;
		const invoiceNumber = this.bf.bigForm.get('invoiceNumber').value;
		const items = this.bf.bigForm.get('actualLineItemsFormArray')?.value?.map(lineItem => {
			const newObj: BillingLineItem = {
				id: lineItem.id,
				item_id: lineItem.item.id,
				item: lineItem.item,
				description: lineItem?.item?.name || lineItem.description,
				job: job.id,
				unit_price: +lineItem?.item?.price || +lineItem.unitPrice,
				invoice: lineItem.invoice || invoiceNumber,
				quantity: lineItem.quantity,
				total: +lineItem.unitPrice * +lineItem.quantity,
				job_invoice: lineItem.job_invoice || invoiceNumber,
				can_edit: lineItem.can_edit
			};

			return newObj;
		});
		// this.bf.bigForm.get('actualLineItemsFormArray').patchValue(newVals, { onlySelf: true, emitEvent: false });

		return this.getSelectedItemTwo().pipe(
			switchMap(job => {
				if (hasBoq) {
					return this.updateBOQ(job.id, items);
				}
				return this.createBOQ(job.id, items);
			}),
			tap(() => this.loaderService.setLoading(false))
		);
	}

	updateBOQ(job_id: number, items): Observable<any> {
		const postData = { job_id, items };
		return this.http.post(environment.api_url + 'v2/boq_action/update_boq', postData);
	}

	createBOQ(job_id: number, items): Observable<any> {
		const postData = { job_id, items };
		return this.http.post(environment.api_url + 'v2/boq_action/create_boq', postData);
	}

	generateBoqQuoteOrInvoice() {
		this.loaderService.setLoading(true);
		const callOutStates = [25, 65, 66, 67, 69, 80, 94, 105, 171, 206, 207];
		const invoiceOnlyStates = [26, 46, 47];
		const date = this.bf.bigForm.get('invoiceDate')?.value;
		const invoiceNumber = this.bf.bigForm.get('invoiceNumber')?.value;
		const notes = this.bf.bigForm.get('invoiceNotes')?.value;
		const vatPercentage = this.bf.bigForm.get('vatPercentage')?.value;
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const isDraft = this.bf.bigForm.get('isDraft')?.value;
		this.bf.addControl('job_quoted', new UntypedFormControl(1, [Validators.required]));

		return this.getSelectedItemTwo().pipe(
			switchMap(job => {
				const isInvoiceOnlyState = callOutStates.indexOf(job.state) !== -1 || invoiceOnlyStates.indexOf(job.state) !== -1;

				if (isInvoiceOnlyState) {
					return this.generateBOQQuote(job.id, invoiceNumber, notes, vatPercentage).pipe(
						take(1),
						switchMap(() => {
							return this.postInvoice(Number(job.id), invoiceNumber, date).pipe(switchMap(() => this.updateJob()));
						})
					);
				} else {
					return this.generateBOQQuote(job.id, invoiceNumber, notes, vatPercentage).pipe(switchMap(() => this.updateJob()));
				}
			}),
			tap(() => this.loaderService.setLoading(false))
		);
	}

	generateDraftBOQQuote() {
		this.loaderService.setLoading(true);
		const invoiceNumber = this.bf.bigForm.get('invoiceNumber')?.value;
		const notes = this.bf.bigForm.get('invoiceNotes')?.value;
		const vatPercentage = this.bf.bigForm.get('vatPercentage')?.value;

		return this.getSelectedItemTwo().pipe(
			switchMap(job => {
				return this.generateBOQQuote(job.id, invoiceNumber, notes, vatPercentage, true).pipe(
					catchError(error => {
						// Save draft quote to local storage
						this.store
							.select(getSelectedItemTwo)
							.pipe(
								skipWhile(x => !x),
								take(1)
							)
							.subscribe((job: any) => {
								localStorage.setItem(
									`DRAFT_${job.id}`,
									JSON.stringify({
										job_id: job.id,
										date: this.bf.bigForm.get('invoiceDate')?.value,
										invoiceNumber: invoiceNumber,
										notes: notes,
										vatPercentage: vatPercentage,
										items: this.bf.bigForm.get('actualLineItemsFormArray')?.value,
										hasBoqItem: this.bf.bigForm.get('hasBoqItem')?.value
									})
								);
							});
						return throwError(error);
					})
				);
			}),
			tap(() => this.loaderService.setLoading(false))
		);
	}

	generateBOQQuote(jobId: number, quoteNumber: string, note: string, vatRate: number, draft?: boolean): Observable<any> {
		let params = new HttpParams().set('job_id', jobId.toString()).set('quote_number', quoteNumber).set('notes', note).set('vat_rate', vatRate.toString());

		// Add 'draft' parameter only if it's provided and has a valid boolean value.
		if (draft !== undefined && (draft === true || draft === false)) {
			params = params.set('draft', draft.toString());
		}

		return this.http.get(environment.api_url + 'v2/invoicing_action/generate_quote', {
			params
		});
	}

	postInvoice(job_id: number, invoice_number: string, invoice_date: any): Observable<any> {
		return this.http.post(environment.api_url + `v2/invoicing_action/generate_invoice`, {
			job_id: job_id,
			invoice_number: invoice_number,
			invoice_date: invoice_date
		});
	}

	getJobLocation() {
		return this.getSelectedItemTwo().pipe(
			skipWhile(x => !x),
			switchMap(itemTwo => {
				return this.http.post(`${environment.api_url}v1/job_action/get_job/`, { job_id: itemTwo?.id }).pipe(
					skipWhile(x => !x),
					take(1),

					switchMap(jobloc => {
						const locationString = jobloc['payload']?.claim?.location;
						const locArray = locationString?.split(',');
						const lat = +locArray[0];
						const lng = +locArray[1];
						return of({ payload: { lat, lng } });
					})
				);
			})
		);
	}

	assignTeam() {
		return this.getSelectedItemTwo().pipe(
			take(1),
			switchMap(job => {
				const team_id = this.bf.bigForm.get('assign_teamleader_id')?.value;
				const data = {
					job_id: job?.id,
					team_id
				};
				return this.http.post(`${environment.api_url}v1/job_action/assign_team/`, data);
			})
		);
	}

	getAssessorDetails() {
		return this.getSelectedItemTwo().pipe(
			switchMap(itemTwo =>
				iif(
					() => itemTwo?.assessor,
					// NOTE: Only if job has assessor, get assessor details, else return empty
					this.http.post<any>(`${environment.api_url}v1/job_action/get_assessor_details/`, {
						job_id: itemTwo?.id
					}),
					of()
				)
			)
		);
	}

	getAssessorDetailsformatted() {
		// NOTE: get_assessor_details function only available in bettersure
		if (!environment.client.includes('bet')) return of();
		return this.getAssessorDetails().pipe(
			map(assessorDetails => ({
				assessor_details: {
					'Full Name': assessorDetails.payload.full_name,
					Email: assessorDetails.payload.email_address,
					'Contact Number': assessorDetails.payload.contact_number
				}
			}))
		);
	}

	getClaimDetailsFormatted() {
		return this.store.select(getFullItemTwo).pipe(
			skipWhile(x => !x),
			take(1),
			map(fullItemTwo => ({
				claim_details: {
					'Claim Type': fullItemTwo?.claim?.loan_information?.claimtype,
					'Date Of Loss': fullItemTwo?.claim?.loan_information?.dateofloss,
					'Appointment Reason': fullItemTwo?.claim?.loan_information?.ia_requested_reason
				}
			}))
		);
	}

	getQuoteQueryClaimDetails() {
		return this.store.select(getFullItemTwo).pipe(
			skipWhile(x => !x),
			take(1),
			map(fullItemTwo => ({
				claim_details: {
					'Claim Type:': fullItemTwo?.claim?.loan_information?.claimtype,
					'Address:': fullItemTwo?.address
				},
				ia_queries: {
					'Query:': fullItemTwo?.job_information?.ia_query.find(query => query.queryreply === null)?.query ?? '-',
					'Response:': fullItemTwo?.job_information?.ia_query.find(query => query.queryreply === null)?.queryreply ?? '-'
				}
			}))
		);
	}

	setJobAppointment() {
		const appointment = this.bf.bigForm.get('appointmentData')?.value;
		const date = moment(appointment.appointmentDatePicker);
		date.hour(appointment.appointmentTimePicker.hour);
		date.minutes(appointment.appointmentTimePicker.minutes);

		const date_formatted = date.format('YYYY-MM-DDTHH:mm:ss');
		const appointment_formatted = {
			range_start: date_formatted,
			appointment_type: appointment.appointmentTime,
			reason: ''
		};

		return this.store.select(getFullItemTwo).pipe(
			take(1),
			switchMap(fullItemTwo => {
				let new_state;
				switch (fullItemTwo.state) {
					case 291: {
						new_state = 292;
						break;
					}
				}
				return this.jobService.updateJob({
					current_state: fullItemTwo.state,
					new_state,
					appointment: appointment_formatted,
					job_id: fullItemTwo.id,
					job_information: { ...fullItemTwo.job_information, return_to_state: fullItemTwo.state }
				});
			})
		);
	}

	showBuildersPhotoWarning(){
		this.modalsService.openModalDirectly(instance => {
			instance.type = 'warning';
			instance.heading = 'Attention'; 
			instance.setMessage(['If no photos are present, or if photos are incorrect Builders will not pay 4-Sure and 4-Sure ','will claim the money back from you.','','Please use the screen that follows to check the installation photos.'	]);
			instance.navButtons	= [
				{
					text: 'continue',
					clickHandler: () => {
						this.modalsService.close();
						this._controller.dispatch(new SetNextNode('PingGoPhotosNode'));
					}
				}	
			]
		})
		return of({})
	}

	buildersSubmitForPayment(){
		this.modalsService.openModalDirectly(instance => {
			instance.type = 'warning';
			instance.heading = 'Attention'; 
			instance.setMessage(['By submitting for payment you confirm that the photos are correct. ','4-Sure will claim the installation fee back if photos do not show the installation.']);
			instance.navButtons	= [
				{
					text: 'continue',
					clickHandler: () => {
						this.modalsService.close();
						this._controller.dispatch(new SetNextNode('AUTO_SELECT_TEMPLATE_DECISION'));
					}
				}	
			]
		})
		return of({})
		return of({})
	}
}
