// eslint-disable-next-line @nx/enforce-module-boundaries
import { environment } from 'apps/studio/src/environments/environment';
import { Store } from '@ngrx/store';
import { SP_GLOBAL_STATES } from '../../sp_globals/configs/flows';
import { PggSpClaimWorkflow } from '../pinggo-sp/configs/workflow/pgg-sp-claim-workflow';
import { FLXAppMenuComponent } from '../../app-shell-features/app-menu/app-menu.component';
import { jobPermissions } from '../../sp_globals/configs/permissions/job.permissions';
import { getCurrentUser, Manifest_0_0_2 } from '@flexus/core';
import { applySilVirtualStates } from '../../sp_globals/sp-virtual-state.helper';
import { PinggoSpSearch } from '../pinggo-sp/configs/pgg-sp.search';
import { filter, map } from 'rxjs/operators';
import * as PggSPStates from './configs/flows';

export const pinggoSp: Manifest_0_0_2 = {
	version: '0.0.2',
	orgKey: environment.client,
	settings: {
		addActionPanel: true,
		addAppBar: true,
		addAppMenu: true,
		addFooter: true,
		addHeaderActions: true,
		addSearchBanner: true,
		addReminder: true
	},
	appMenu: (instance: FLXAppMenuComponent, store: Store<any>) => {
		return store.select(getCurrentUser).pipe(
			filter(user => !!user),
			map(res => {
				const createRoles = [1, 6];
				const menuItems = [
					{ id: 1, name: 'Workflow', routerLink: '/workflow', iconType: 'app-menu-workflow' },
					{ id: 11, name: 'Chart', routerLink: 'flow-chart', iconType: 'app-menu-workflow' }
				];

				if (res?.user.roles.some(role => createRoles.includes(role))) {
					menuItems.push({
						id: 2,
						name: 'New Claim',
						routerLink: '/workflow/detail',
						iconType: 'app-menu-new-claim'
					});
				}

				return menuItems;
			})
		);
	},
	virtualStatesFunction: applySilVirtualStates,
	appSearch: PinggoSpSearch,
	permissions: {
		itemTwo: jobPermissions
	},
	manifestItems: {
		...SP_GLOBAL_STATES,
		21: PggSPStates.PGG_21,
		26: PggSPStates.PGG_26,
		46: PggSPStates.PGG_46,
		201: PggSPStates.PGG_201,
		244: PggSPStates.PGG_244,
		370: PggSPStates.PGG_370,
		378: PggSPStates.PGG_378,
		384: PggSPStates.PGG_384,
		workflow: PggSpClaimWorkflow
	}
};
