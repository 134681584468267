/* eslint-disable @typescript-eslint/no-explicit-any */
import { Store } from '@ngrx/store';
import { Flow_0_0_2, getFullItemTwo, BigFormService, getSubmissionData, getCurrentUser, getSelectedItemTwo, getAllInfo } from '@flexus/core';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';

import { forkJoin, of } from 'rxjs';
import { skipWhile, map, take, switchMap, filter, pluck } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import gql from 'graphql-tag';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { environment } from 'apps/studio/src/environments/environment';
import { HttpClient } from '@angular/common/http';
import moment from 'moment';

export const PGG_201: Flow_0_0_2 = {
	id: '201',
	name: 'replacement_stock_arrived_set_new_appointment',
	itemType: 'flow',
	actionPanel: instance => setActionPanelItems(instance, ['job-details', 'notes', 'documents']),
	onStateInit: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: inst => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (_store: Store, _bf: BigFormService) => {
			return _store.select(getFullItemTwo).pipe(
				map((itemTwo: any) => {
					if (itemTwo) {
						let claimref;
						if (itemTwo?.claim?.applicant && itemTwo?.claim?.loan_information) {
							if (itemTwo?.claim?.loan_information?.mavenclaimnumber) {
								claimref = itemTwo?.claim?.loan_information?.mavenclaimnumber;
							} else {
								claimref = itemTwo?.claim?.loan_information?.voucher_key;
							}
							return `Replacement Stock Arrived - Set New Appointment: ${claimref} - ${itemTwo?.claim?.applicant?.first_name}`;
						} else {
							return 'Replacement Stock Arrived - Set New Appointment';
						}
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {
		skills: {
			serviceVariable: 'spService',
			functionName: 'getSkills',
			responseSlice: 'skills',
			errorMessage: 'No skills were found!'
		},
        jobLocationData: {
			errorMessage: 'The job location was not returned!',
			serviceVariable: 'spService',
			functionName: 'getJobLocation',
			responseSlice: 'payload'
		},
		teamleaderLocations: {
			errorMessage: 'Team leader locations not returned!',
			directCall: (http) => {
				return http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
					pluck('payload'),
					filter((x: any) => !!x),
					map((teamleaders: any[]) => {
						return teamleaders;
					})
				);
			}
		}
	},
	instructions: {
		editRoles: {
			0: 'Replacement Stock Arrived - Set New Appointment'
		},
		viewRoles: {
			0: 'Replacement Stock Arrived'
		}
	},
	startNode: 'ReplacementStockArrivedNotification',
	nodes: {
		ReplacementStockArrivedNotification: {
			initFormFields: bf => {
				bf.addControl('new_state', new UntypedFormControl('--'));
			},
			serverCalls: {
				keyValueList: {
					errorMessage: 'No job data could be retrieved',
					directCall: (http, store) => {
                        return forkJoin([
                            store
							.select(getFullItemTwo)
							.pipe(
								skipWhile(x => !x),
								take(1),
								map(res => res as any)
							),
                            store.select(getAllInfo).pipe(
								skipWhile(x => !x),
								take(1),
								map((res: any) => res)
							)
                        ])
							.pipe(
								map(([itemTwo, info]) => {
                                    let appointmentname 
                                    const { appointment } = itemTwo;
                                    const { appointment_types } = info;
                                    const { claim } = itemTwo
                                    const { loan_information } = claim
								const { onsiteperson, onsitecontact } = loan_information
                                    const appointmentdate = appointment[0]?.range_start;
								    const appointmenttype = appointment[0]?.appointment_type;
                                    
                                    for (let i = 0; i < appointment_types?.length; i++) {
                                        if (appointmenttype === appointment_types[i]?.id) {
                                            appointmentname = appointment_types[i]?.name;
                                        }
                                    }
									const produc = itemTwo?.claim?.loan_information?.voucher_type ?? 'No voucher type';
									const list = {
										'Claim Type ': itemTwo?.claim?.type ?? '',
										'Address ': itemTwo?.address ?? '',
										Product: `${produc}`,
                                        'Appointment Date ': `${moment(appointmentdate).format(moment.HTML5_FMT.DATE)}`,
                                        'Appointment Time': `${appointmentname} ${moment(appointmentdate).format(moment.HTML5_FMT.TIME)}`,
                                        'On-site contact Person': `${onsiteperson}`,
                                        'Contact number': `${onsitecontact}`,
										'Client  ': itemTwo?.claim?.applicant ? `${itemTwo?.claim?.applicant?.first_name} ${itemTwo?.claim?.applicant?.surname}` : '',
										'Contact Number ': itemTwo?.claim?.applicant?.contact_number ?? '',
										'Cell Number ': itemTwo?.claim?.loan_information?.cellnumber ?? '',
									};
									return [list];
								})
							);
					}
				}
			},
			component: {
				children: [
					{
						component: 'FLXHeadingWithInstructionsComponent',
						inputs: {
							title: 'Set New Appointment',
							instructions: ['New stock has arrived to replace the damaged stock from the store.', 'Please contact the client and arrange a new appointment.']
						}
					},
					{
						component: 'FLXKeyValueListComponent',
						inputs: {
							data$: 'keyValueList'
						}
					}
				]
			},
			navs: [
				{
					text: 'continue',
					nextNode: 'SetAppointment',
					color: 'primary'
				}
			]
		},
		SetAppointment: {
			inputs: {
				minDate: new Date()
			},
			checkValidityForFields: ['appointmentData'],
			initFormFields: (bf, item, instance, sq) => {
				bf.addControl(
					'appointmentData',
					new UntypedFormGroup({
						appointmentDatePicker: new UntypedFormControl(null, Validators.required),
						appointmentTime: new UntypedFormControl(null, Validators.required),
						appointmentTimePicker: new UntypedFormControl(null, Validators.required),
						appointmentDateType: new UntypedFormControl(null, Validators.required),
						skill: new UntypedFormControl('Dish Installation')
					})
				);
				bf.patchValues({
					appointmentData: bf.getControl('appointmentData')?.value || ''
				});
				bf.patchValues({ new_state: 22 });
			},
			serverCalls: {
				claimaintKeyValues: {
					errorMessage: 'Claimant detail could not be retrieved',
					directCall: (_http: HttpClient, _store: Store, _sq: any, _bf) => {
						return _store
							.select(getFullItemTwo)
							.pipe(
								skipWhile((x: any) => !x),
								take(1),
								map(res => res as any)
							)
							.pipe(
								map(job => {
									const claimantinfo = {
										'Claimant Details ': {
											'Full name ': `${job.claim?.applicant?.first_name} ` + `${job.claim?.applicant?.surname}`,
											'Contact Number': job?.claim?.loan_information.ContactNumber,
											'Mobile Number ': job?.claim?.loan_information.cellnumber,
											'On-site contact name ': job?.claim?.loan_information.onsiteperson
										}
									};
									return [claimantinfo];
								})
							);
					}
				},
				customer_details: {
					errorMessage: 'No customer details were found!',
					directCall: (http, store, sq) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											claim {
												applicant {
													first_name
													surname
													contact_number
												}
												loan_information {
													onsitecontact
												}
											}
										}
									}
								`,
								store.select(getFullItemTwo).pipe(
									skipWhile(fi => !fi),
									take(1),
									map(res => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((qdat: any) => {
									return [
										{
											'Client Name': `${qdat.first_name} ${qdat.surname}`
										},
										{ 'Contact Number': `${qdat.contact_number}` },
										{ 'Mobile Number': `${qdat.onsitecontact}` }
									];
								})
							);
					}
				},
				onsite_details: {
					errorMessage: 'Onsite details not found!',
					directCall: (http, store, sq, bf) => {
						return sq
							.queryObject(
								gql`
									{
										fullItemTwo {
											claim {
												loan_information {
													onsiteperson
													onsitecontact
												}
											}
										}
									}
								`,
								store.select(getFullItemTwo).pipe(
									skipWhile(f => !f),
									take(1),
									map(res => ({ fullItemTwo: res }))
								)
							)
							.pipe(
								map((queryData: any) => {
									return [{ 'Onsite Contact Name': `${queryData.onsiteperson}` }, { 'Onsite Contact Number': `${queryData.onsitecontact}` }];
								})
							);
					}
				},
				appointmentTypes: {
					serviceVariable: 'service',
					functionName: 'getAppointmentTypes',
					errorMessage: 'No Appointment Types could be found!'
				}
			},
			navs: [
				{
					text: 'Continue',
					optIntoValidation: true,
					linkType: 'portal',
					color: 'primary',
					nextNode: 'AssignTeamLeader',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: 'Appointment not set!',
							directCall: (http, store, sq, bf) => {
								return forkJoin([
									store.select(getFullItemTwo).pipe(
										skipWhile(x => !x),
										take(1)
									),
									store.select(getSubmissionData).pipe(
										skipWhile(x => !x),
										take(1)
									)
								]).pipe(
									map(([job, submit]) => {
										const job_id = job?.id;
										const current_state = job?.state;
										const appointment = submit.appointment;
										const data = {
											job_id: job_id,
											current_state: current_state,
											appointment: appointment,
											// new_state: 22
										};
										return data;
									}),
									switchMap(data => {
										return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
									})
								);
							}
						}
					}
				}
			],
			component: 'FLXJobAppointmentComponent'
		},
        AssignTeamLeader: {
			name: 'Select Team Leader / Map',
			checkValidityForFields: ['assign_teamleader_id'],
			initFormFields: bf => {
				bf.addControl('assign_teamleader_id', new UntypedFormControl('', Validators.required));
			},
			serverCalls: {
				tlListData: {
					errorMessage: '',
					directCall: (http:HttpClient) => {
						return http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
							pluck('payload'),
							filter((x: any) => !!x),
							map((teamleaders: any[]) =>
								teamleaders.map(teamLeader => {
									return {
										display: teamLeader.full_name,
										value: teamLeader.id,
										teamLeader,
										// shouldHaveImage: false
									};
								})
							)
						);
					}
				}
			},
			component: 'AssignTLComponent',
			inputs: [
				{
					joblocation$: 'jobLocationData',
					teamleadersPositions$: 'teamleaderLocations'
				}
			],

			navs: [
				// {
				//   text: 'Update Map',
				//   nextNode: ''
				// },
				{
					text: 'Submit',
					nextNode: 'SubmissionSuccess',
					color: 'primary',
					optIntoValidation: true,
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: "Couldn't update job!",
							directCall: (http, store, sq, bf) => {
								return forkJoin([
									store.select(getSelectedItemTwo).pipe(
										skipWhile(x => !x),
										take(1),
										map(res => res as any)
									),
									store.select(getCurrentUser).pipe(
										skipWhile(x => !x),
										take(1),
										map(res => res as any)
									),
									of(bf.bigForm.get('assign_teamleader_id')?.value).pipe(
										skipWhile(x => !x),
										take(1),
										map(rest => rest)
									)
								]).pipe(
									switchMap(([j, uid, lead]) => {
										const job = j as any;
										const user = uid as any;
										const job_id = job?.id;
										const staffmember = user.id;
										const team = lead[0];

										const data = {
											job_id: job_id,
											staffmember: staffmember,
											team_id: team
											// new_state: 22
										};
										return http.post(`${environment.api_url}v1/job_action/assign_team/`, data);
									})
								);
							}
						}
					},

					location: 'right'
				}
			]
		},
		SubmissionSuccess: { component: 'FLXSuccessTickComponent' }
	},
	bigFormToStoreMapper: {
		// new_state: 'new_state',
		appointmentData: [
			appointment => {
				if (appointment && appointment.appointmentDatePicker && appointment.appointmentTimePicker) {
					const date = moment(appointment.appointmentDatePicker);
					date.hour(appointment.appointmentTimePicker.hour);
					date.minutes(appointment.appointmentTimePicker.minutes);
					const date_formatted = date.format('YYYY-MM-DDTHH:mm:ss');
					return {
						range_start: date_formatted,
						range_end: date_formatted,
						appointment_type: appointment.appointmentTime
					};
				}
			},
			'appointment'
		]
	}
};
